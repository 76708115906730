<template>
  <div class="pump-control ly-wrap">
    <pump-nav selected="2"></pump-nav>

    <div class="error-wrap" v-if="isError">
      <div class="error-img"></div>
      <p>{{ errorMsg || '系统异常' }} </p>
    </div>

    <template v-else>
      <div class="ly-center">
        <div class="ct-title">
          <div class="name">
            <div class="icon"></div>
            阴河沟泵站
            <span> {{ loadTime | formatData }}</span>
          </div>
          <div class="btn-wrap">
            <div class="btn" @click="handleZBXZ(controlData[0].pumpId)">主备选择</div>
            <div class="btn-box">
              <div :class="[controlData[0].data[0].data=='1'?'on':'','auto']"
                   @click="handleControl(controlData[0].data[0],1,controlData[0].pumpId)">自动
              </div>
              <div :class="[controlData[0].data[0].data=='0'?'on':'','hand']"
                   @click="handleControl(controlData[0].data[0],0,controlData[0].pumpId)">手动
              </div>
            </div>
            <div class="ct-name">控制模式</div>
          </div>
        </div>
        <div class="division-line"></div>
      </div>
      <div class="ly-center">
        <div class="ct-list">
          <div class="ct-item" v-for="item in controlData" :key="item.pumpDataId">
            <div class="it-left">
              <div class="yq">
                <div class="red" v-if="item.data[2].data=='0'"></div>
              </div>
              <div class="name">{{ item.name }}</div>
              <div class="info">优先级{{ item.data[1].data }} <span v-if="item.data[2].data == '1'">运行中</span>
                <span v-else style="color: #D93636">停止</span>
              </div>
            </div>
            <div class="it-right">
              <div class="up">
                <div class="btn"
                     @click="handleStart(item.data[2].data,item.data[3].id,item.name,item.pumpId)">启动
                </div>
                <div class="btn"
                     @click="handleStop(item.data[2].data,item.data[4].id,item.name,item.pumpId)">停止
                </div>
              </div>
              <div class="down">
                <div class="lt">
                  <p>运行频率</p>
                  <p>{{ item.data[5].data }}HZ</p>
                </div>
                <div class="btn" @click="handleFrequency(item.data[5],item.name,item.pumpId)">频率设置</div>
              </div>
            </div>
          </div>
          <div class="not-more">
            <div class="icon"></div>
            <div class="txt">
              <p>所有的泵都在上面了，</p>
              <p>如果没看够可以去其它泵站哦。</p>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- 主备排序弹框   -->
    <mt-popup v-model="zbpxPOP" class="ly-pop-wrap zbpx-wrap" popup-transition="popup-fade">
      <div class="pop-title">主备排序</div>
      <div class="pop-body">
        <div class="zbpx-tips">自动模式下将按照以下顺序启停泵</div>
        <div class="zbpx-table">
          <div class="zbpx-title line">
            <div class="sort">排序</div>
            <div class="name">泵名称</div>
            <div class="operation">点击排序</div>
          </div>
          <div class="info">
            <div class="line" v-for="(item,index) in pumpList" :key="index">
              <div class="sort">{{ index + 1 }}</div>
              <div class="name">{{ item.name }}</div>
              <div class="operation">
                <div class="up" @click="handleUp(index)" v-show="index>0"></div>
                <div class="down" @click="handleDown(index)" v-show="index<pumpList.length-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pop-foot">
        <div class="cancel" @click="zbpxPOP = false">取消</div>
        <div class="sure" @click="sureSort">确定</div>
      </div>
    </mt-popup>
    <!--  频率设置弹框  -->
    <mt-popup v-model="plszPOP" class="ly-pop-wrap plsz-wrap" popup-transition="popup-fade">
      <div class="pop-title">频率设置</div>
      <div class="pop-body">
        <div class="pop-form">
          <div class="form-item">
            <div class="form-left">泵名称:</div>
            <div class="form-right">{{ plszData.pumpName }}</div>
          </div>
          <div class="form-item">
            <div class="form-left">当前频率:</div>
            <div class="form-right">{{ plszData.data }}</div>
          </div>
          <div class="form-item">
            <div class="form-left">设置频率:</div>
            <div class="form-right">
              <input type="text" class="form-input" v-model="plszData.value">
              Hz
            </div>
          </div>
        </div>
      </div>
      <div class="pop-foot">
        <div class="cancel" @click="plszPOP = false">取消</div>
        <div class="sure" @click="sureFrequency">确定</div>
      </div>
    </mt-popup>
  </div>
</template>

<script>
import {postPumpControl, postVariableData, getPumpListByPumpId, postRemoteControl} from '../../api/api.js'
import pumpNav from './modules/PumpNav.vue'
import store from '../../store/index.js'
import {MessageBox, Toast} from 'mint-ui';
import {getSelectPump, getUserInfo} from "../../utils/auth";
import moment from "moment";

export default {
  name: 'PumpControl',
  components: {pumpNav},
  computed: {
    controlData() {
      let success = this.$store.state.sundries.pumpControlData.success
      if (success) {
        return this.$store.state.sundries.pumpControlData.result
      } else {
        return []
      }
    },
    loadTime() {
      return this.$store.state.sundries.pumpControlData.collectTime
    }
  },
  filters: {
    formatData(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  data() {
    return {
      isError: false,// 异常
      errorMsg: '',// 异常
      plszPOP: false,// 频率设置
      plszData: {},// 频率数据
      zbpxPOP: false,// 主备排序
      zbxpData: [],// 主备选择的初始化数据
      pumpList: [],
    }
  },
  mounted() {
    this.loadBase()
  },
  created() {
  },
  methods: {
    async loadBase() {
      const selectPump = await getSelectPump()
      const userInfo = await getUserInfo()
      const body = {
        pumpId: selectPump.id,
        userId: userInfo.id
      }
      postPumpControl(body).then(res => {
        this.isError = false
        if (res.data.success) {
          store.dispatch('PUMP_CONTROL_DATA', res.data)
        } else {
          this.isError = true
          this.errorMsg = res.data.message || '异常'
        }
      })
    },
    // 上
    handleUp(i) {
      let temp = this.pumpList[i]
      this.$set(this.pumpList, i, this.pumpList[i - 1])
      this.$set(this.pumpList, i - 1, temp)
    },
    // 下
    handleDown(i) {
      let temp = this.pumpList[i]
      this.$set(this.pumpList, i, this.pumpList[i + 1])
      this.$set(this.pumpList, i + 1, temp)
    },
    handleZBXZ(pumpId) {
      const body = {
        pumpId: pumpId
      }
      getPumpListByPumpId(body).then(res => {
        this.pumpList = res.data.result
        this.zbxpData = res.data.result
        this.zbpxPOP = true
      })
    },
    // 确认排序
    sureSort() {
      const body = {
        beforeList: this.zbxpData,
        dataList: this.pumpList
      }
      postRemoteControl(body).then(res => {
        this.zbpxPOP = false
        Toast('操作成功！');
      })
    },
    //   手自动控制
    handleControl(item, data, pumpId) {
      // 用一个点位来判断当前的状态
      if (item.data == '1') {
        if (data == 1) {
          Toast('当前已经是自动模式！');
        } else {
          MessageBox.close()
          MessageBox.confirm('确定设置控制模式为手动模式?').then(action => {
            this.postData(item.id, data, 1, pumpId, 3)
          }).catch((err) => {
            console.log(err)
          });
        }
      } else {
        if (data == 0) {
          Toast('当前已经是手动模式！');
        } else {
          MessageBox.close()
          MessageBox.confirm('确定设置控制模式为自动模式?').then(action => {
            this.postData(item.id, data, 0, pumpId, 3)
          }).catch((err) => {
            console.log(err)
          });
        }
      }

    },

    // 启动按钮
    handleStart(state, variableId, name, pumpId) {
      // 判断手自动
      if (this.controlData[0].data[0].data == '0') {
        if (state == '0') {
          MessageBox.close()
          MessageBox.confirm('确定启动' + name + '?').then(action => {
            this.firstPost(variableId, 1, state, pumpId)
            setTimeout(() => {
              this.postData(variableId, 0, state, pumpId, 1);
            }, 800);
          }).catch((err) => {
            console.log(err)
          });
        } else {
          Toast('当前泵已经启动！');
        }
      } else {
        Toast('当前是自动模式，请先更改控制模式为手动！');
      }

    },

    //  停止按钮
    handleStop(state, variableId, name, pumpId) {
      // 判断手自动
      if (this.controlData[0].data[0].data == '0') {
        if (state == '1') {
          MessageBox.close()
          MessageBox.confirm('确定停止' + name + '?').then(action => {
            this.firstPost(variableId, 1, state, pumpId)
            setTimeout(() => {
              this.postData(variableId, 0, state, pumpId, 1);
            }, 800);
          }).catch((err) => {
            console.log(err)
          });
        } else {
          Toast('当前泵已经处于停止状态！');
        }
      } else {
        Toast('当前是自动模式，请先更改控制模式为手动！');
      }
    },
    //  频率设置
    handleFrequency(data, name, pumpId) {
      this.plszPOP = true
      this.plszData = data
      this.plszData.value = null
      this.plszData.pumpId = pumpId
      this.plszData.pumpName = name
    },
    // 确认频率设置
    sureFrequency() {
      // 判断输入值没有
      if (this.plszData.value) {
        this.postData(this.plszData.id, this.plszData.value, this.plszData.data, this.plszData.pumpId, 2)
        this.plszPOP = false
      } else {
        Toast({
          message: '请输入频率',
          position: 'top',
        });
      }
    },
    // 统一的脉冲请求 第一次
    firstPost(variableId, data, dataBefore, pumpId) {
      const body = {
        variableId: variableId,
        data: data,
        pumpId: pumpId,
        dataBefore: dataBefore,
        typeCode: '1',
      }
      postVariableData(body)
    },
    // 统一的控制请求
    postData(variableId, data, dataBefore, pumpId, typeCode) {
      const body = {
        variableId: variableId,
        data: data,
        dataBefore: dataBefore,
        pumpId: pumpId,
        typeCode: typeCode,
      }
      postVariableData(body).then(res => {
        this.loadBase()
        Toast('操作成功！');
      })
    },
  },
  destroyed: function () {
  },
}
</script>

<style scoped lang="less">
// 频率设置弹框
.plsz-wrap {
  top: 40%;
}

// 主备排序弹框
.zbpx-wrap {
  top: 30%;

  .zbpx-tips {
    font-size: 0.22rem;
    font-weight: 400;
    color: #999999;
    padding-left: 0.4rem;
  }

  .zbpx-table {
    width: 100%;

    .line {
      width: 100%;
      height: 0.88rem;
      line-height: 0.88rem;
      border-bottom: 1px solid #E2E2E2FF;
      display: flex;
      text-align: left;
      color: #333333;
      font-size: 0.28rem;

      .sort {
        width: 0.6rem;
        padding-left: 0.4rem;
      }

      .name {
        flex: 1;
      }

      .operation {
        width: 1.4rem;
        padding-right: 0.4rem;

        .up {
          float: left;
          width: 0.44rem;
          height: 0.44rem;
          background: #0088FF url("~@/assets/img/home/arrow-on.png") no-repeat center;
          background-size: 0.29rem;
          transform: rotate(-90deg);
          border-radius: 0.1rem;
          margin-top: 0.2rem;
        }

        .down {
          float: right;
          margin-top: 0.2rem;
          width: 0.44rem;
          height: 0.44rem;
          background: #0088FF url("~@/assets/img/home/arrow-on.png") no-repeat center;
          background-size: 0.29rem;
          transform: rotate(90deg);
          border-radius: 0.1rem;
        }
      }
    }

    //.line:first-child {
    //  .operation {
    //    .up {
    //      display: none;
    //    }
    //  }
    //}
    //
    //.line:last-child {
    //  .operation {
    //    .down {
    //      display: none;
    //    }
    //  }
    //}

    .zbpx-title {
      font-size: 0.22rem;
      color: #999999;
      line-height: 1rem;
    }
  }

}

.pump-control {
  width: 100%;
  height: 100%;
  background-color: #F2F2F2FF;
}

.ct-title {
  width: 100%;
  height: 2rem;
  margin-top: 0.2rem;
  //border-radius: 10px 10px 0 0;
  overflow: hidden;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  background: #f2f2f2;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.09);

  .name {
    height: 0.82rem;
    line-height: 0.82rem;
    font-weight: bold;
    color: #0088FF;
    font-size: 0.3rem;
    text-indent: 0.35rem;

    span {
      font-weight: normal;
      font-size: 0.24rem;
      padding-left: 0.2rem;
    }

    .icon {
      display: inline-block;
      width: 0.32rem;
      height: 0.3rem;
      background: url("~@/assets/img/i-pump.png") no-repeat center;
      background-size: 0.32rem;
      position: relative;
      top: 0.02rem;
    }
  }

  .btn-wrap {
    padding: 0.08rem 0.3rem 0;
    height: 1.2rem;
    position: relative;

    .ct-name {
      height: 0.82rem;
      line-height: 0.82rem;
      color: #333333;
      font-size: 0.3rem;
      position: absolute;
      right: 0.35rem;
      top: -0.7rem;
    }

    .btn {
      float: left;
      width: 1.76rem;
      height: 0.88rem;
      line-height: 0.88rem;
      background: #0088FF;
      box-shadow: 0px 4px 4px 0px rgba(0, 136, 255, 0.28);
      border-radius: 10px;
      font-size: 0.24rem;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;

    }

    .btn-box {
      float: right;
      width: 4rem;
      height: 0.88rem;
      display: flex;

      div {
        line-height: 0.88rem;
        text-align: center;
        flex: 1;
        background: #F2F2F2;
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.13);
        border-radius: 44px 0 0 44px;
        color: #0088FF;
        border-top: 1px solid #ffffff;
        font-size: 0.36rem;
      }

      .hand {
        border-radius: 0 44px 44px 0;
      }

      .on {
        color: #FFFFFF;
        background: #0088FF;
        box-shadow: inset 0 5px 5px 0 rgba(0, 0, 0, 0.13);
      }
    }
  }


}

//  分割
.division-line {
  width: 100%;
  height: 0.04rem;
  background: url("~@/assets/img/home/division.png") repeat-x;
  background-size: 0.04rem;
}

.ct-list {
  width: 100%;
  height: auto;
  background-color: #DEEFFFFF;

  .ct-item {
    display: flex;
    height: 2.4rem;
    border-bottom: 1px dashed #0088FFFF;
    padding: 0.1rem 0;

    .it-left {
      flex: 1;
      border-right: 1px dashed #0088FFFF;

      .yq {
        width: 1.13rem;
        height: 1.15rem;
        background: url("~@/assets/img/parts/y3.png") no-repeat center;
        background-size: 1.13rem;
        margin: 0.27rem auto 0.1rem;
        position: relative;

        .red {
          width: 0.3rem;
          height: 0.3rem;
          background: url("~@/assets/img/parts/red.png") no-repeat center;
          background-size: 0.3rem;
          position: absolute;
          left: 0.5rem;
          top: 0.34rem;
        }
      }

      .name {
        font-size: 0.3rem;
        font-weight: bold;
        color: #333333;
        line-height: 0.5rem;
        text-align: center;
      }

      .info {
        text-align: center;
        color: #0088FF;
        font-size: 0.22rem;
        font-weight: bold;

        span {
          color: #00BF00FF;
        }
      }
    }

    .it-right {
      width: 3.2rem;
      margin-left: 0.3rem;
      padding: 0 0.5rem;

      .btn {
        line-height: 0.88rem;
        width: 1.44rem;
        color: #0088ff;
        height: 0.88rem;
        text-align: center;
        background: #e6f7ff;
        border-radius: 10px;
        border: 1px solid #0088FF;
        box-shadow: 0 2px 2px 0 rgb(184, 245, 245, .66);
      }


      .up {
        width: 100%;
        height: 0.9rem;
        padding-top: 0.3rem;

        .btn {
          float: left;
        }

        .btn:last-child {
          float: right;
        }
      }

      .down {
        width: 100%;
        height: 0.9rem;
        padding-top: 0.3rem;

        .lt {
          width: 1.44rem;
          height: 0.88rem;
          float: left;

          p {
            font-size: 0.28rem;
            font-weight: bold;
            color: #333333;
            margin: 0;
            line-height: 0.4rem;
            text-align: center;
          }
        }

        .btn {
          float: right;
          color: #ffffff;
          background: #0088FF;
          box-shadow: 0 2px 2px 0 rgba(0, 136, 255, 0.28);
        }
      }
    }
  }
}

.not-more {
  width: 100%;
  height: 0.9rem;
  background: #DEEFFFFF;
  text-align: center;
  padding-top: 0.1rem;

  .icon {
    display: inline-block;
    width: 1.13rem;
    height: 0.58rem;
    background: url("~@/assets/img/ct-empty.png") no-repeat center;
    background-size: 1.13rem;
    margin-right: 0.18rem;
  }

  .txt {
    display: inline-block;
    text-align: left;

    p {
      font-size: 0.22rem;
      font-weight: 400;
      color: #A0BEDA;
      margin: 0;
      line-height: 0.42rem;
    }
  }
}

</style>

